.hero_section label {
  font-size: 50px;
  line-height: 70px;
  /* bottom: 4rem; */
  width: 50vw;
  height: 90vh;
}
.hero_section {
  height: 100vh;
  width: 100%;
}
.hero_section img {
  z-index: -9;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.hero_section {
  position: relative;
  /* width: 100vw; */
}

.hero__slider,
.slider_content_one {
  height: 100vh !important;
}

.hero__slider {
  height: 100vh;
  background: transparent;
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: #000;
  line-height: 60px;
  text-align: center;
  font-family: var(--font-Montserrat);
}

.hero__slider .react__arrowPrev,
.hero__slider .react__arrowNext {
  color: #fff;
}

#main_slider .slick-list,
#main_slider .slick-slider,
#main_slider {
  /* width: 100vw; */
  height: 100vh;
  /* border-radius: 0px 0px 0px 150px; */
  /* border-radius: 0% 0% 0% 25%; */
  margin-left: auto;
}
#main_slider .slick-slide {
  display: block !important;
  height: 100vh;
}

.staticText {
  font-family: var(--fancy-font);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  color: #000000;
  font-weight: 300;
  padding: 2rem;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -35%);
  width: fit-content;
}

.slider_content_one {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 55%;
  transform: translate(-55%, -50%);
  width: fit-content;
  padding: 5rem;
  display: flex !important;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.slider_content_one .first {
  line-height: 60px;
  font-size: 50px;
  font-family: var(--fancy-font);
  font-weight: 500;
  padding-top: 3rem;
}

@media screen and (min-width: 1600px) {
  .hero_section label {
    font-size: 80px;
    line-height: 90px;
  }
}

@media screen and (min-width: 1400px) {
  .slider_content_one video {
    /* background: #fff; */
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media screen and (max-width: 768px) {
  .hero_section label {
    font-size: 40px;
    line-height: 55px;
  }
  .slider_content_one {
    top: 55%;
    left: 55%;
    width: 70%;
  }
}

@media screen and (max-width: 580px) {
  /* .hero_section label {
    display: none;
  } */
  .hero_section label {
    font-size: 30.125px;
    line-height: 40px;
    width: 250px;
  }
  #main_slider .slick-list,
  #main_slider .slick-slider,
  #main_slider {
    width: 100vw;
  }
  .slider_content_one {
    padding: 1rem;
    width: 100%;
  }
}

@media screen and (max-width: 370px) {
  .hero_section label {
    font-size: 35.125px;
    line-height: 45px;
    width: 200px;
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, 50%);
  }
}
