.slider_div {
  height: 550px;
  width: 100% !important;
}
.slider_div img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
