/* //old css bundle */
.custom_row {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem;
}
.col-lg-6 {
  width: 50% !important;
}
.custom_child_div1 {
  width: 50%;
}
.custom_child_div2 {
  width: 50%;
}
.custom_child_div2 img {
  width: 100%;
}
.custom_hero {
  padding-top: 3rem;
}
.counts .content {
  padding: 0;
}

.justify-content-center {
  justify-content: center !important;
}
.flex-column {
  flex-direction: column !important;
}
.d-flex {
  display: flex !important;
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.align-items-stretch {
  align-items: stretch !important;
}
.pt-4 {
  padding-top: 1.5rem !important;
}
.mb-5 {
  margin-bottom: 3rem !important;
}
.col-lg-3 {
  flex: 0 0 auto;
  width: 25%;
}
.prl-1 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.herosection_div {
  width: 50%;
}

@media (min-width: 1200px) {
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
}
@media (max-width: 1200px) {
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
}
@media screen and (max-width: 992px) {
  .herosection_div {
    width: 100%;
  }
  .herosection {
    flex-direction: column;
  }
  .about-w-full {
    width: 100% !important;
  }
  .isoTopContainer {
    margin: auto;
    width: 100%;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .md-pb-2 {
    margin-bottom: 2rem !important;
  }
}
@media (min-width: 992px) {
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
}

.testimonials,
.team {
  display: block !important;
}
.hero-container {
  padding-right: 6%;
}

/* mobileDev */
.sectionProcess {
  padding-right: 30px;
  padding-left: 30px;
  text-align: center;
  /* background: #f3f9ff; */
}
.processIcon {
  border-bottom: 1px solid rgb(182, 182, 182);
}
.processIcon h2 {
  margin-bottom: 3rem;
  font-size: 32px;
}
.opicon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.opIconItem {
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 200px;
  height: 200px;
  color: #fff;
  font-size: 20px;
  font-weight: 800;
  text-align: center;
}
.circleText {
  display: flex;
  width: 70px;
  height: 70px;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  border-radius: 50%;
  background-color: #727272;
}

.iconGrid {
  display: -ms-grid;
  display: grid;
  margin-top: 10px;
  margin-bottom: 10px;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto;
  margin-bottom: 2rem;
}
.iconGrid img {
  width: 55px;
  height: 55px;
}
.ourSkill {
  padding: 40px 30px;
  display: flex;
  justify-content: space-between;
  max-width: 900px;
  align-items: stretch;
  text-align: left;
}
.ourSkillLeft {
  width: 50%;
  border-left: 1px solid rgb(182, 182, 182);
  padding-left: 4rem;
}
.ourSkillLeft h4 {
  margin-bottom: 1.5rem;
}
.ourSkillRight {
  width: 50%;
}
.ourSkillLeft .btn-get-started {
  border: 1px solid #ccc;
  padding: 7px;
  border-radius: 5px;
  display: flex !important;
  max-width: fit-content;
  height: auto;
  justify-content: space-between !important;
  /* background-color: #1781bb !important; */
  color: #ffff !important;
}
.ourSkillLeft .btn-get-started span {
  max-width: 180px;
  width: 160px;
}
.ourSkillLeft .btn-get-started svg {
  font-size: 1.5rem;
  color: #fff;
  transform: translate(-8px, -10px);
}
.ourSkillLeft .btn-get-started:hover {
  border: 1px solid #1781bb;
}
.ourSkillLeft .btn-get-started:hover svg {
  transform: translate(1px, -20px);
}
/* end */

.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: #3498db;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}
.back-to-top i {
  font-size: 28px;
  color: #fff;
  line-height: 0;
}
.back-to-top:hover {
  background: #57aae1;
  color: #fff;
}
.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

@media screen and (max-width: 520px) {
  .opIconItem {
    width: 120px;
  }
}

/*--------------------------------------------------------------
# Disable AOS delay on mobile
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
  .ourSkill {
    flex-direction: column;
  }
  .ourSkillRight {
    margin-bottom: 2rem;
  }
  .ourSkillLeft {
    padding: 0;
    border: none;
  }
  .opicon {
    width: 100%;
    margin: auto;
  }
  .ourSkill {
    padding: 0;
    padding-top: 3rem;
  }
  .hero .container {
    padding: 10px;
  }
  .ourSkillRight,
  .ourSkillLeft {
    width: 100%;
  }
  .more_services,
  .more_services .row {
    padding: 0 !important;
  }
  .more_services h4 {
    padding: 10px;
  }
}
@media screen and (max-width: 520px) {
  .more-services .card-body {
    width: 100% !important;
  }
  #faq .container {
    padding: 10px !important;
  }
  .counts .content .count-box p {
    margin: 0 !important;
  }
}
/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  z-index: 997;
  transition: all 0.5s;
  padding: 22px 0;
  background: #fff;
}
#header.header-scrolled {
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  padding: 12px 0;
}
#header .logo h1 {
  font-size: 30px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 1px;
}
#header .logo h1 a,
#header .logo h1 a:hover {
  color: #222222;
  text-decoration: none;
}
#header .logo img {
  padding: 0;
  margin: 0;
  max-height: 40px;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
.navbar {
  padding: 0;
}
.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}
.navbar li {
  position: relative;
}
.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  font-size: 15px;
  font-weight: 400;
  color: #555555;
  white-space: nowrap;
  transition: 0.3s;
}
.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}
.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover > a {
  color: #3498db;
}
.navbar .getstarted,
.navbar .getstarted:focus {
  background: #3498db;
  padding: 8px 25px;
  margin-left: 30px;
  border-radius: 50px;
  color: #fff;
}
.navbar .getstarted:hover,
.navbar .getstarted:focus:hover {
  color: #fff;
  background: #4aa3df;
}
.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 30px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-radius: 4px;
}
.navbar .dropdown ul li {
  min-width: 200px;
}
.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 14px;
}
.navbar .dropdown ul a i {
  font-size: 12px;
}
.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover > a {
  color: #3498db;
}
.navbar .dropdown:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}
.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}
.navbar .dropdown .dropdown:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}
@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }
  .navbar .dropdown .dropdown:hover > ul {
    left: -100%;
  }
}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
  color: #222222;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}
.mobile-nav-toggle.bi-x {
  color: #fff;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }
}
.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(9, 9, 9, 0.9);
  transition: 0.3s;
  z-index: 999;
}
.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}
.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  border-radius: 8px;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}
.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #222222;
}
.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover > a {
  color: #3498db;
}
.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
}
.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}
.navbar-mobile .dropdown ul li {
  min-width: 200px;
}
.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}
.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}
.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover > a {
  color: #3498db;
}
.navbar-mobile .dropdown > .dropdown-active {
  display: block;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  height: auto;
  padding-top: 80px;
  padding-bottom: 5rem;
}
#hero h1 {
  margin: 0 0 10px 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  color: black;
}
#hero h2 {
  color: #484848;
  margin-bottom: 50px;
  font-size: 20px;
  line-height: 30px;
}
#hero .btn-get-started {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 1rem;
  height: auto;
  border-radius: 4px;
  transition: 0.5s;
  color: #333;
  border: 2px solid #dee2e6;
  display: flex !important;
  justify-content: space-between !important;
  max-width: fit-content;
  padding-bottom: 0;
}
#hero .btn-get-started:hover {
  background: #1781bb !important;
  border: 2px solid #1781bb;
  color: #fff !important;
}
#hero .btn-get-started svg {
  font-size: 2rem;
  transition: all ease 0.3s;
  color: #333;
  transform: translate(12px, -12px);
}
#hero .btn-get-started:hover svg {
  transform: translate(14px, -14px);
  color: #fff !important;
}
#hero .animated {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}
@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }
}
@media (max-width: 991px) {
  #hero .animated {
    -webkit-animation: none;
    animation: none;
  }
  #hero .hero-img {
    text-align: center;
  }
  #hero .hero-img img {
    width: 100%;
  }
}
@media (max-width: 768px) {
  #hero {
    margin-top: 20px;
  }
  #hero h1 {
    font-size: 28px;
    line-height: 36px;
  }
  #hero h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }
  #hero .hero-img img {
    width: 70%;
  }
}
@media (max-width: 575px) {
  #hero .hero-img img {
    width: 100%;
  }
}

@-webkit-keyframes up-down {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}
/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 15px 0;
  overflow: hidden;
}

.section-bg {
  background-color: #f7fbfe;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}
.section-title h2 {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
  color: #222222;
}
.section-title h2::before,
.section-title h2::after {
  content: "";
  width: 50px;
  height: 2px;
  background: #000000;
  display: inline-block;
}
.section-title h2::before {
  margin: 0 15px 10px 0;
}
.section-title h2::after {
  margin: 0 0 10px 15px;
}
.section-title p {
  margin: 15px 0 0 0;
}

/*--------------------------------------------------------------
# Clients
--------------------------------------------------------------*/
.client_custom_parent {
  width: max-content !important;
}
.clients .container {
  overflow-x: scroll;
}
.clients .container::-webkit-scrollbar {
  display: none;
}
.clients {
  background: #f3f9fd;
  padding: 10px 0;
  text-align: center;
}
.clients .col-lg-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  margin: auto;
}
.clients img {
  width: 50%;
  filter: grayscale(100);
  transition: all 0.4s ease-in-out;
  display: inline-block;
  padding: 10px 0;
}
.clients img:hover {
  filter: none;
  transform: scale(1.1);
}
/* @media (max-width: 768px) {
  .clients img {
    width: 40%;
  }
} */

/* @media (max-width: 575px) {
  .clients img {
    width: 30%;
  }
} */

/*--------------------------------------------------------------
# About Us
--------------------------------------------------------------*/
.hoverBlue:hover {
  color: #1781bb;
}
.about .content {
  align-items: baseline;
  padding: 2rem;
}
.about .content h3 {
  font-weight: 600;
  font-size: 26px;
}
.about .content ul {
  list-style: none;
  padding: 0;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.about .content ul li {
  padding-left: 28px;
  position: relative;
  background-position: 0 7px;
  background-size: 10px;
  background-repeat: no-repeat;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5em;
  list-style-type: none;
  margin-bottom: 1rem;
}
.about .content ul li + li {
  margin-top: 10px;
}
.about .content ul i {
  position: absolute;
  left: 0;
  top: 2px;
  font-size: 20px;
  color: #3498db;
  line-height: 1;
}
.skill_li li {
  padding-left: 20px;
}
.about .content p:last-child {
  margin-bottom: 0;
}
.about .content p {
  color: #4d4d4d;
  margin-top: 0;
  margin-bottom: 12px;
  line-height: 2;
  font-weight: 300;
}
.about .content .btn-learn-more {
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 32px;
  border-radius: 50px;
  transition: 0.3s;
  line-height: 1;
  color: #000000;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  margin-top: 6px;
  border: 2px solid #000000;
}
.about .content .btn-learn-more:hover {
  background: #3498db;
  color: #fff;
  text-decoration: none;
  border: 2px solid #3498db;
}

/*--------------------------------------------------------------
# Counts
--------------------------------------------------------------*/
.counts {
  padding-top: 0;
}
.counts .content {
  padding: 0;
}
.counts .content h3 {
  font-weight: 700;
  font-size: 34px;
  color: #222222;
}
.counts .content p {
  margin-bottom: 0;
}
.counts .content .count-box {
  padding: 20px 0;
  width: 100%;
}
.counts .content .count-box i {
  display: block;
  font-size: 36px;
  color: #3498db;
  float: left;
  line-height: 0;
}
.counts .content .count-box span {
  font-size: 36px;
  line-height: 30px;
  display: block;
  font-weight: 700;
  color: #222222;
  margin-left: 50px;
}
.counts .content .count-box p {
  padding: 15px 0 0 0;
  margin: 0 0 0 50px;
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  color: #484848;
}
.counts .content .count-box a {
  font-weight: 600;
  display: block;
  margin-top: 20px;
  color: #484848;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  transition: ease-in-out 0.3s;
}
.counts .content .count-box a:hover {
  color: #6f6f6f;
}
@media (max-width: 1024px) {
  .counts .image {
    text-align: center;
  }
  .counts .image img {
    max-width: 70%;
  }
}
@media (max-width: 667px) {
  .counts .image img {
    max-width: 100%;
  }
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services .icon-box {
  padding: 30px;
  position: relative;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 0 29px 0 rgba(68, 88, 144, 0.12);
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
  z-index: 1;
}
.services .icon-box::before {
  content: "";
  position: absolute;
  background: #f2f2f2;
  right: -60px;
  top: -40px;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  transition: all 0.3s;
  z-index: -1;
}
.services .icon-box:hover::before {
  background: #000000;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 0px;
}
.services .icon {
  margin: 0 auto 20px auto;
  padding-top: 10px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  background: #000000;
  transition: all 0.3s ease-in-out;
}
.services .icon i {
  font-size: 36px;
  line-height: 1;
  color: #fff;
}
.services .title {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 16px;
  margin-top: 8px;
  line-height: 1.4;
}
.services .title a {
  color: #111;
}
.services .description {
  font-size: 15px;
  line-height: 28px;
  margin-bottom: 0;
  color: #959595;
}
.services .icon-box:hover .title a,
.services .icon-box:hover .description {
  color: #fff;
}
.services .icon-box:hover .icon {
  background: #fff;
}
.services .icon-box:hover .icon i {
  color: #3498db;
}

/*--------------------------------------------------------------
# More Services
--------------------------------------------------------------*/
.more-services {
  padding-top: 20px;
}
.more-services .card {
  border: 0;
  padding: 160px 20px 20px 20px;
  position: relative;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  /* background-position: center center; */
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  /* border: 1px solid rgba(0, 0, 0, 0.125); */
  /* border-radius: 0.25rem; */
}
.more-services .card-body {
  z-index: 10;
  background: rgba(255, 255, 255, 0.9);
  padding: 15px 30px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  transition: 0.3s;
  transition: ease-in-out 0.4s;
  border-radius: 5px;
  flex: 1 1 auto;
  padding: 1rem 1rem;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.more-services .card-title {
  font-weight: 700;
  text-align: center;
  margin-bottom: 15px;
}
.more-services .card-title a {
  color: #222222;
}
.more-services .card-text {
  color: #5e5e5e;
}
.more-services .read-more a {
  color: #777777;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  transition: 0.4s;
}
.more-services .read-more a:hover {
  text-decoration: underline;
}
.more-services .card:hover .card-body {
  background: #000000;
}
.more-services .card:hover .read-more a,
.more-services .card:hover .card-title,
.more-services .card:hover .card-title a,
.more-services .card:hover .card-text {
  color: #fff;
}

/*--------------------------------------------------------------
# Features
--------------------------------------------------------------*/
.features .icon-box {
  display: flex;
  align-items: center;
  padding: 20px;
  background: #f6f6f6;
  transition: ease-in-out 0.3s;
}
.features .icon-box i {
  font-size: 32px;
  padding-right: 10px;
  line-height: 1;
}
.features .icon-box h3 {
  font-weight: 700;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-size: 16px;
}
.features .icon-box h3 a {
  color: #222222;
  transition: ease-in-out 0.3s;
}
.features .icon-box h3 a:hover {
  color: #3498db;
}
.features .icon-box:hover {
  background: #eef7fc;
}

/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/
.testimonials .testimonial-wrap {
  padding-left: 50px;
}
.testimonials .testimonials-carousel,
.testimonials .testimonials-slider {
  overflow: hidden;
}
.testimonials .testimonial-item {
  box-sizing: content-box;
  padding: 30px 30px 30px 60px;
  margin: 30px 15px;
  min-height: 200px;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
  position: relative;
  background: #fff;
}
.testimonials .testimonial-item .testimonial-img {
  width: 90px;
  border-radius: 10px;
  border: 6px solid #fff;
  position: absolute;
  left: -45px;
}
.testimonials .testimonial-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: #111;
}
.testimonials .testimonial-item h4 {
  font-size: 14px;
  color: #999;
  margin: 0;
}
.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
  color: #e1f0fa;
  font-size: 26px;
}
.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}
.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
}
.testimonials .testimonial-item p {
  font-style: italic;
  margin: 15px auto 15px auto;
}
.testimonials .swiper-pagination {
  margin-top: 20px;
  position: relative;
}
.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #3498db;
}
.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #3498db;
}
@media (max-width: 767px) {
  .testimonials .testimonial-wrap {
    padding-left: 0;
  }
  .testimonials .testimonials-carousel,
  .testimonials .testimonials-slider {
    overflow: hidden;
  }
  .testimonials .testimonial-item {
    padding: 30px;
    margin: 15px;
  }
  .testimonials .testimonial-item .testimonial-img {
    position: static;
    left: auto;
  }
}

/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/
.portfolio .portfolio-item {
  margin-bottom: 30px;
}
.portfolio #portfolio-flters {
  padding: 0;
  margin: 0 auto 20px auto;
  list-style: none;
  text-align: center;
}
.portfolio #portfolio-flters li {
  cursor: pointer;
  display: inline-block;
  padding: 8px 15px 10px 15px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  color: #222222;
  margin-bottom: 5px;
  transition: all 0.3s ease-in-out;
  border-radius: 3px;
}
.portfolio #portfolio-flters li:hover,
.portfolio #portfolio-flters li.filter-active {
  color: #fff;
  background: #3498db;
}
.portfolio #portfolio-flters li:last-child {
  margin-right: 0;
}
.portfolio .portfolio-wrap {
  transition: 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background: rgba(34, 34, 34, 0.6);
}
.portfolio .portfolio-wrap::before {
  content: "";
  background: rgba(34, 34, 34, 0.6);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: all ease-in-out 0.3s;
  z-index: 2;
  opacity: 0;
}
.portfolio .portfolio-wrap img {
  transition: all ease-in-out 0.3s;
}
.portfolio .portfolio-wrap .portfolio-info {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  transition: all ease-in-out 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 20px;
}
.portfolio .portfolio-wrap .portfolio-info h4 {
  font-size: 20px;
  color: #fff;
  font-weight: 600;
}
.portfolio .portfolio-wrap .portfolio-info p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
  font-style: italic;
}
.portfolio .portfolio-wrap .portfolio-links {
  text-align: center;
  z-index: 4;
}
.portfolio .portfolio-wrap .portfolio-links a {
  color: #fff;
  margin: 0 5px 0 0;
  font-size: 28px;
  display: inline-block;
  transition: 0.3s;
}
.portfolio .portfolio-wrap .portfolio-links a:hover {
  color: #3498db;
}
.portfolio .portfolio-wrap:hover::before {
  opacity: 1;
}
.portfolio .portfolio-wrap:hover img {
  transform: scale(1.2);
}
.portfolio .portfolio-wrap:hover .portfolio-info {
  opacity: 1;
}

/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.portfolio-details {
  padding-top: 40px;
}
.portfolio-details .portfolio-details-slider img {
  width: 100%;
}
.portfolio-details .portfolio-details-slider .swiper-pagination {
  margin-top: 20px;
  position: relative;
}
.portfolio-details
  .portfolio-details-slider
  .swiper-pagination
  .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #3498db;
}
.portfolio-details
  .portfolio-details-slider
  .swiper-pagination
  .swiper-pagination-bullet-active {
  background-color: #3498db;
}
.portfolio-details .portfolio-info {
  padding: 30px;
  box-shadow: 0px 0 30px rgba(34, 34, 34, 0.08);
}
.portfolio-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}
.portfolio-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}
.portfolio-details .portfolio-info ul li + li {
  margin-top: 10px;
}
.portfolio-details .portfolio-description {
  padding-top: 30px;
}
.portfolio-details .portfolio-description h2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}
.portfolio-details .portfolio-description p {
  padding: 0;
}

/*--------------------------------------------------------------
# Team
--------------------------------------------------------------*/
.team .member {
  margin-bottom: 20px;
  overflow: hidden;
  text-align: center;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}
.team .member .member-img {
  position: relative;
  overflow: hidden;
}
.team .member .social {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 40px;
  opacity: 0;
  transition: ease-in-out 0.3s;
  background: rgba(255, 255, 255, 0.85);
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.team .member .social a {
  transition: color 0.3s;
  color: #222222;
  margin: 0 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.team .member .social a i {
  line-height: 0;
}
.team .member .social a:hover {
  color: #3498db;
}
.team .member .social i {
  font-size: 18px;
  margin: 0 2px;
}
.team .member .member-info {
  padding: 25px 15px;
}
.team .member .member-info h4 {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 18px;
  color: #222222;
}
.team .member .member-info span {
  display: block;
  font-size: 13px;
  font-weight: 400;
  color: #aaaaaa;
}
.team .member .member-info p {
  font-style: italic;
  font-size: 14px;
  line-height: 26px;
  color: #777777;
}
.team .member:hover .social {
  opacity: 1;
}

/*--------------------------------------------------------------
# Pricing
--------------------------------------------------------------*/
.pricing .box {
  padding: 20px;
  background: #f9f9f9;
  text-align: center;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  border: 2px solid #f9f9f9;
}
.pricing .box h3 {
  font-weight: 400;
  padding: 15px;
  margin-top: 15px;
  font-size: 18px;
  font-weight: 600;
  color: #222222;
}
.pricing .box h4 {
  font-size: 42px;
  color: #3498db;
  font-weight: 500;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 20px;
}
.pricing .box h4 sup {
  font-size: 20px;
  top: -15px;
  left: -3px;
}
.pricing .box h4 span {
  color: #bababa;
  font-size: 16px;
  font-weight: 300;
}
.pricing .box ul {
  padding: 0;
  list-style: none;
  color: #222222;
  text-align: center;
  line-height: 20px;
  font-size: 14px;
}
.pricing .box ul li {
  padding-bottom: 16px;
}
.pricing .box ul i {
  color: #3498db;
  font-size: 18px;
  padding-right: 4px;
}
.pricing .box ul .na {
  color: #ccc;
  text-decoration: line-through;
}
.pricing .box .btn-wrap {
  padding: 15px;
  text-align: center;
}
.pricing .box .btn-buy {
  display: inline-block;
  padding: 10px 40px 12px 40px;
  border-radius: 50px;
  border: 2px solid #3498db;
  color: #3498db;
  font-size: 14px;
  font-weight: 400;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  transition: 0.3s;
}
.pricing .box .btn-buy:hover {
  background: #3498db;
  color: #fff;
}
.pricing .recommended {
  border-color: #3498db;
}
.pricing .recommended .btn-buy {
  background: #3498db;
  color: #fff;
}
.pricing .recommended .btn-buy:hover {
  background: #2383c4;
  border-color: #2383c4;
}

/*--------------------------------------------------------------
# F.A.Q
--------------------------------------------------------------*/
.faq .faq-item {
  margin: 20px 0;
  padding: 20px 0;
  border-bottom: 1px solid #eeeeee;
}
.faq .faq-item i {
  color: #8bc4ea;
  font-size: 24px;
  float: left;
  line-height: 0;
  padding: 13px 0 0 0;
  margin: 0;
}
.faq .faq-item h4 {
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  margin: 0 0 10px 32px;
  font-family: "Poppins", sans-serif;
}
.faq .faq-item p {
  font-size: 15px;
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .contact-about h3 {
  font-size: 28px;
  margin: 0 0 10px 0;
  padding: 0;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 1px;
  color: #222222;
}
.contact .contact-about p {
  font-size: 14px;
  line-height: 24px;
  font-family: "Raleway", sans-serif;
  color: #888;
}
.contact .social-links {
  padding-bottom: 20px;
}
.contact .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #fff;
  color: #3498db;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
  border: 1px solid #3498db;
}
.contact .social-links a:hover {
  background: #3498db;
  color: #fff;
}
.contact .info {
  color: #444444;
}
.contact .info i {
  font-size: 32px;
  color: #3498db;
  float: left;
  line-height: 1;
}
.contact .info p {
  padding: 0 0 10px 42px;
  line-height: 28px;
  font-size: 14px;
}
.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}
.contact .php-email-form .error-message br + br {
  margin-top: 25px;
}
.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}
.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}
.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}
.contact .php-email-form .form-group {
  margin-bottom: 20px;
}
.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  padding: 10px 15px;
}
.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
  border-color: #3498db;
}
.contact .php-email-form button[type="submit"] {
  background: #3498db;
  border: 0;
  padding: 10px 24px;
  color: #fff;
  transition: 0.4s;
  border-radius: 50px;
}
.contact .php-email-form button[type="submit"]:hover {
  background: #2383c4;
}
@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
  padding: 15px 0;
  background: #f8f8f8;
  min-height: 40px;
  margin-top: 86px;
}
@media (max-width: 992px) {
  .breadcrumbs {
    margin-top: 72px;
  }
}
.breadcrumbs h2 {
  font-size: 28px;
  font-weight: 400;
  margin: 0;
}
.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
}
@media (max-width: 992px) {
  .breadcrumbs ol {
    margin-top: 10px;
  }
}
.breadcrumbs ol li + li {
  padding-left: 10px;
}
.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: #3c3c3c;
  content: "/";
}
@media (max-width: 768px) {
  .breadcrumbs .d-flex {
    display: block !important;
  }
  .breadcrumbs ol {
    display: block;
  }
  .breadcrumbs ol li {
    display: inline-block;
  }
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  background: #fff;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  padding: 30px 0;
  color: #222222;
  font-size: 14px;
}
#footer .credits {
  font-size: 13px;
  padding-top: 5px;
  color: #222222;
}
#footer .footer-links a {
  color: #222222;
  padding-left: 15px;
}
#footer .footer-links a:first-child {
  padding-left: 0;
}
#footer .footer-links a:hover {
  color: #3498db;
}
